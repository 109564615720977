#divChangePassword {
    
    h4 {
        margin-bottom: 1rem;
    }

    form{
        max-width: 300px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}