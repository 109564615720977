#wrapper {
    display: grid;
    height: 100vh;
}

#divLogin {
    width: 300px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-self: center;
    align-self: center;

    form {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        justify-self: center;
        align-self: center;
    }
}